<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Deine Inserate</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            Deine Inserate
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>{{ totalInserats }} Ergebnisse gefunden</ion-card-subtitle>
        </ion-card-header>
      </ion-card>

      <inseratCard v-for="(inserat, key) in inserats" :key="key" :id="inserat.id" :tags="true"></inseratCard>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardContent, IonCardSubtitle, IonCardTitle, IonGrid, IonRow, IonCol } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import moment from 'moment';
import inseratCard from '../../components/inseratCard.vue';

export default defineComponent({
  name: 'UserInserats',
  data () {
    return {
      inserats: [],
      totalInserats: 0,
      showGrid: false,

    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    inseratCard
  },
  created() {



    axios.get(process.env.VUE_APP_API_URL + '/user-inserats', {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken
      }
    })
        .then(response => {
          this.inserats = response.data["inserats"];
          this.totalInserats = response.data["total"];
        });

  },
});

</script>

<style scoped>


</style>
