
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardContent, IonCardSubtitle, IonCardTitle, IonGrid, IonRow, IonCol } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import moment from 'moment';
import inseratCard from '../../components/inseratCard.vue';

export default defineComponent({
  name: 'UserInserats',
  data () {
    return {
      inserats: [],
      totalInserats: 0,
      showGrid: false,

    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    inseratCard
  },
  created() {



    axios.get(process.env.VUE_APP_API_URL + '/user-inserats', {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken
      }
    })
        .then(response => {
          this.inserats = response.data["inserats"];
          this.totalInserats = response.data["total"];
        });

  },
});

